import React, { useEffect, useState } from 'react';
import Header from '../Header';
import {
    Button, Card, CardBody, CardImg, Col, Container, Input, Row, Collapse, FormGroup, Label
} from 'reactstrap';
import { Diet } from 'helpers/api_url';
import { toast } from 'react-toastify';
import image from '../../../assets/images/Diet/diet-plans-1.jpg';
import { Link, useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';

export const DietPage = () => {
    const [tabledata, setTableData] = useState([]); // Original API data
    const [filteredData, setFilteredData] = useState([]); // Displayed data
    const [searchTerm, setSearchTerm] = useState(''); // Simple search term

    // Advanced Search State
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [nameFilter, setNameFilter] = useState(null);
    const [typeFilter, setTypeFilter] = useState(null);

    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => { setIsSmallScreen(window.matchMedia("(max-width: 567px)").matches); };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    // Fetching data from API
    useEffect(() => {
        Diet.list()
            .then(res => {
                setTableData(res.data.food);
                setFilteredData(res.data.food);
            })
            .catch(err => toast.error(err.response ? err.response.data.message : err.message));
    }, []);

    // Toggle Advanced Search Section
    const toggleAdvancedSearch = () => setAdvancedSearch(!advancedSearch);

    // Handle Simple Search
    const handleSimpleSearch = () => {
        const data = tabledata.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredData(data);
    };

    // Handle Advanced Search
    const handleAdvancedSearch = () => {
        const data = tabledata.filter(item =>
            (!nameFilter || item.name.toLowerCase().includes(nameFilter.value.toLowerCase())) &&
            (!typeFilter || item.type?.toLowerCase() === typeFilter.value.toLowerCase())
        );
        setFilteredData(data);
        // toggleAdvancedSearch();
    };

    // Extract Unique Names and Types for ReactSelect
    const dietNameOptions = [...new Set(tabledata.map(item => item.name))].map(name => ({ value: name, label: name }));
    const dietTypeOptions = [
        { label: 'Vegan', value: 'Vegan' },
        { label: 'vegetrain', value: 'veg' },
        { label: 'Egetrain', value: 'egg' },
        { label: 'Non-vegetrain', value: 'nonveg' },
    ];

    const handleDietView = diet_id => navigate('/view-diet/' + diet_id);

    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5">
                <Container className="mt-3" fluid>
                    <Col md="2" lg={1} xs={12} className="d-lg-none d-block">
                        <p className='text-center fs-3'>Follow</p>
                        <div className="d-flex align-items-center justify-content-center mb-3">
                            <Link to="#"><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link><br />
                            <Link to="https://www.instagram.com/p/DCZFL_GTjNx/?igsh=MWU5Nnc3eWQzMGI3NA%3D%3D" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link><br />
                            <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link><br />
                        </div>

                    </Col>
                    <Row className="justify-content-center">
                        <Col md="3" lg="3" xs="12" className=''>
                            <div className=" justify-content-center ">
                                <Row>
                                    <div className="input-group mb-3">
                                        <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for diets (e.g., name)" className="form-control" />
                                        <span className="input-group-text btn btn-primary text-light" onClick={handleSimpleSearch} id="basic-addon1"><i className='bx bx-search' /></span>
                                        {advancedSearch ? <Button color="danger" className="ms-2 d-lg-none d-block" onClick={toggleAdvancedSearch}><i className="bx bx-x px-2"></i></Button> : <Button color="success" className="ms-2 d-lg-none d-block" onClick={toggleAdvancedSearch}><i className="bx bx-filter-alt px-2"></i></Button>}
                                    </div>
                                </Row>
                            </div>
                            {/* Advanced Search */}
                            <Collapse isOpen={isSmallScreen ? advancedSearch : !advancedSearch}>
                                <h4 className="mb-3 text-center">Advanced Search</h4>
                                <Row>
                                    <Col md={12}>
                                            <Card>
                                                <ReactSelect options={dietNameOptions} placeholder="Select Diet Name" value={nameFilter} onChange={setNameFilter} />
                                            </Card>
                                    </Col>
                                    <Col md={12}>
                                            <Card>
                                                <ReactSelect options={dietTypeOptions} placeholder="Select Diet Type" value={typeFilter} onChange={setTypeFilter} />
                                            </Card>
                                    </Col>
                                    <div className="text-center mt-3">
                                        <Button color="primary" block onClick={handleAdvancedSearch}>Search</Button>
                                    </div>
                                </Row>

                            </Collapse>
                        </Col>
                        <Col lg={7} md={7} xs={12}>
                            <h1 className="text-center mb-4">Fittestwarrior Diet</h1>
                            <div className='scrollable-col' >
                                {
                                    searchTerm === "" && filteredData.length > 0 ? <>
                                        <h3>Food & Diets</h3>
                                        <p className="font-format fs-4">
                                            Discover nutritional advice and diet plans crafted to support various health and fitness goals. Whether you’re interested in weight management, muscle gain, or healthier eating, our Food & Diets section offers insights to help you make informed dietary choices.
                                        </p>
                                    </>
                                        : null
                                }

                                {/* Diet Cards */}
                                <Row>
                                    {filteredData.length ? (
                                        filteredData.map((item, idx) => (
                                            <Col md={3} sm={6} className="mb-4" key={idx}>
                                                <Card className="shadow">
                                                    <CardImg top src={image} alt="Diet" />
                                                    <CardBody className="text-center">
                                                        <h6>{item.name}</h6>
                                                        <Button color="dark" onClick={() => handleDietView(item.id)}>Show Details</Button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))
                                    ) : (
                                        <>
                                            <Card>
                                                <CardBody className='d-flex flex-column align-items-center justify-content-center'>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/nqtddedc.json"
                                                        trigger="loop"
                                                        colors="primary:#e83a30"
                                                        style={{ width: '250px', height: '250px' }}>
                                                    </lord-icon>
                                                    <h1>No Diet found</h1>
                                                </CardBody>
                                            </Card>
                                        </>
                                    )}
                                </Row>
                            </div>
                        </Col>
                        <Col md="2" lg={1} xs={2} className="media-position d-none d-lg-block">
                            <b>Follow</b>
                            <div className="justify-content-end">
                                <Link to="#"><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link><br />
                                <Link to="https://www.instagram.com/p/DCZFL_GTjNx/?igsh=MWU5Nnc3eWQzMGI3NA%3D%3D" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link><br />
                                <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link><br />
                            </div>

                        </Col>
                    </Row>





                </Container>
            </div>
        </React.Fragment>
    );
};
