import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardImg, Col, Container, Input, Row, FormGroup, Label, Collapse } from 'reactstrap';
import { exercise } from 'helpers/api_url';
import { toast } from 'react-toastify';
import './Exercise.css';
import image from '../../../assets/images/Execise/ex2.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header';
import { ExerciseLike } from './ExerciseLike';
import ReactSelect from "react-select";
export const ExerciseData = () => {
    const [tabledata, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
    // Check for screen size changes
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => { setIsSmallScreen(window.matchMedia("(max-width: 567px)").matches); };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const navigate = useNavigate();
    useEffect(() => {
        exercise.list()
            .then(res => {
                setTableData(res.data.exercise);
                setFilteredData(res.data.exercise);
            })
            .catch(err => toast.error(err.response ? err.response.data.message : err.message));
    }, []);

    // Handle Simple Search
    const handleSimpleSearch = () => {
        const filtered = tabledata.filter(item =>
            item.exercise_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            JSON.parse(item.area_of_body_part).includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
    };

    // Handle Input Change for Advanced Search
    const handleInputChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption?.value || "";
        setSearchInputs({ ...searchInputs, [name]: value });
    };
    const [searchInputs, setSearchInputs] = useState({
        muscleGroup: '',
        experience_level: '',
        experience_type: '',
        equipments: '',
        area_of_body_part: '',
        gravity: '',
        muscle_size: '',
        intensity: '',
        gravity: '',
        mechanics: '',
        techniques: '',
    });
    // Handle Advanced Search
    const handleAdvancedSearch = () => {
        const filtered = tabledata.filter((item) => {
            return (
                (!searchInputs.muscleGroup || item.muscle_group?.toLowerCase().includes(searchInputs.muscleGroup.toLowerCase())) &&
                (!searchInputs.experience_level || item.experience_level?.toLowerCase().includes(searchInputs.experience_level.toLowerCase())) &&
                (!searchInputs.experience_type || JSON.parse(item.exercise_type).includes(searchInputs.experience_type.toLowerCase())) &&
                (!searchInputs.equipments || JSON.parse(item.equipments).includes(searchInputs.equipments.toLowerCase())) &&
                (!searchInputs.area_of_body_part || (item.area_of_body_part).includes(searchInputs.area_of_body_part.toLowerCase())) &&
                (!searchInputs.gravity || (item.gravity).includes(searchInputs.gravity.toLowerCase())) &&
                (!searchInputs.muscle_size || (item.muscle_size).includes(searchInputs.muscle_size.toLowerCase())) &&
                (!searchInputs.intensity || (item.intensity_mobility).includes(searchInputs.intensity.toLowerCase())) &&
                (!searchInputs.mechanics || (item.mechanics).includes(searchInputs.mechanics.toLowerCase()))
                // (!searchInputs.techniques || (item.techniques).includes(searchInputs.techniques.toLowerCase()))
            );
        });
        setFilteredData(filtered);
    };

    // Toggle Advanced Search Collapse
    const toggleAdvancedSearch = () => setIsAdvancedOpen(!isAdvancedOpen);

    const handleExerciseView = (exercise_id) => navigate('/view-excercise/' + exercise_id);
    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-3" >
                <Container className="mt-3" fluid>
                    <Col md="2" lg={1} xs={12} className="d-lg-none d-block">
                        <p className='text-center fs-3'>Follow</p>
                        <div className="d-flex align-items-center justify-content-center mb-3">
                            <Link to="#"><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link><br />
                            <Link to="https://www.instagram.com/p/DCZFL_GTjNx/?igsh=MWU5Nnc3eWQzMGI3NA%3D%3D" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link><br />
                            <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link><br />
                        </div>

                    </Col>
                    <Row className="justify-content-center">
                        <Col md="3" lg="3" xs="12" className=''>
                            {/* Simple Search Section */}
                            <div className=" justify-content-center ">

                                <Row>
                                    <div className="input-group mb-3">
                                        <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="form-control" placeholder="Search for exercises (e.g., name, body part...)" />
                                        <span className="input-group-text btn btn-primary text-light" onClick={handleSimpleSearch} id="basic-addon1"><i className='bx bx-search' /></span>
                                        {isAdvancedOpen ? <Button color="danger" className="ms-2 d-lg-none d-block" onClick={toggleAdvancedSearch}><i className="bx bx-x px-2"></i></Button> : <Button color="success" className="ms-2 d-lg-none d-block" onClick={toggleAdvancedSearch}><i className="bx bx-filter-alt px-2"></i></Button>}
                                    </div>
                                </Row>
                            </div>
                            <Collapse isOpen={isSmallScreen?isAdvancedOpen:!isAdvancedOpen}>
                                <Row className=''>
                                    <Col md="12">
                                        <h3 className='text-center'>Advance Search</h3>
                                        <Card>

                                            <ReactSelect id="equipments" placeholder="Equipments" name="equipments" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Bodyweight", label: "Bodyweight" },
                                                { value: "Machines_Cardio", label: "Machines_Cardio" },
                                                { value: "Machines_Chest", label: "Machines_Chest" },
                                                { value: "Machines_Shoulder", label: "Machines_Shoulder" },
                                                { value: "Machines_Arm & Wrist", label: "Machines_Arm & Wrist" },
                                                { value: "Machine_Back", label: "Machine_Back" },
                                                { value: "Machines_Abdominal", label: "Machines_Abdominal" },
                                                { value: "Machines_Legs & Ankle", label: "Machines_Legs & Ankle" },
                                                { value: "Machines_All in One", label: "Machines_All in One" },
                                                { value: "Machines_Dual_Multi", label: "Machines_Dual_Multi" },
                                                { value: "Machines_Others", label: "Machines_Others" },
                                                { value: "Free Weight", label: "Free Weight" },
                                                { value: "Barbells_Rod", label: "Barbells_Rod" },
                                                { value: "Balls", label: "Balls" },
                                                { value: "Bands_Straps", label: "Bands_Straps" },
                                                { value: "Ropes", label: "Ropes" },
                                                { value: "Mix Accessories", label: "Mix Accessories" },
                                                { value: "None", label: "None" },
                                            ]} />
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card>
                                            <ReactSelect id="muscleGroup" name="muscleGroup" placeholder="Muscle Group" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Head, Neck & Jaw", label: "Head, Neck & Jaw" },
                                                { value: "Chest_Pectoral", label: "Chest_Pectoral" },
                                                { value: "Shoulder_Deltoid", label: "Shoulder_Deltoid" },
                                                { value: "Back", label: "Back" },
                                                { value: "Arm & Wrist", label: "Arm & Wrist" },
                                                { value: "Abdominal", label: "Abdominal" },
                                                { value: "Legs & Ankle", label: "Legs & Ankle" },
                                                { value: "Joints_Muscles", label: "Joints_Muscles" },
                                            ]} />
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card>
                                            <ReactSelect id="experience_level" placeholder="Experience Level" name="experience_level" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Just Start", label: "Just Start" },
                                                { value: "Beginner", label: "Beginner" },
                                                { value: "Moderate", label: "Moderate" },
                                                { value: "Advance", label: "Advance" },
                                                { value: "Professional", label: "Professional" },
                                            ]} />
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card>

                                            <ReactSelect id="experience_type" placeholder="Exercise Type" name="experience_type" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Cardio", label: "Cardio" },
                                                { value: "Exercises", label: "Exercises" },
                                                { value: "Cool Down", label: "Cool Down" },
                                                { value: "Yoga", label: "Yoga" },
                                                { value: "Pilates", label: "Pilates" },
                                                { value: "Crossfit_HysportX", label: "Crossfit_HysportX" },
                                                { value: "Callisthenics", label: "Callisthenics" },
                                            ]} />
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card>
                                            <ReactSelect id="gravity" placeholder="Gravity" name="gravity" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Pull", label: "Pull" },
                                                { value: "Push", label: "Push" },
                                            ]} />
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card>
                                            <ReactSelect id="muscle_size" placeholder="Muscle Size" name="muscle_size" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "small", label: "small" },
                                                { value: "medium", label: "medium" },
                                                { value: "big", label: "big" },
                                            ]} />
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card>
                                            <ReactSelect id="intensity" placeholder="Intensity" name="intensity" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Normal", label: "Normal" },
                                                { value: "medium", label: "medium" },
                                                { value: "High", label: "High" },
                                            ]} />
                                        </Card>
                                    </Col>

                                    <Col md="6">
                                        <Card>
                                            <ReactSelect id="area_of_body_part" placeholder="Area of Body Part" name="area_of_body_part" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Trunk/upper", label: "Trunk/upper" },
                                                { value: "Arms", label: "Arms" },
                                                { value: "Leg/Lower", label: "Leg/Lower" },
                                            ]} />
                                        </Card>
                                    </Col>

                                    {/* <Col md="6">
                                    <FormGroup>
                                        <Label>Techniques</Label>
                                        <Card>
                                            <ReactSelect id="techniques" name="techniques"  onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Static", label: "Static" },
                                                { value: "Dynamic", label: "Dynamic" },
                                                { value: "Polymatric", label: "Polymatric" },
                                                { value: "Explosive", label: "Explosive" },
                                            ]} />
                                        </Card>
                                    </FormGroup>
                                </Col> */}

                                    <Col md="6">
                                        <Card>
                                            <ReactSelect id="mechanics" placeholder="Mechanics" name="mechanics" onChange={(option, actionMeta) => handleInputChange(option, actionMeta)} options={[
                                                { value: "Bilateral/compound", label: "Bilateral/compound" },
                                                { value: "unilateral/Isolateral", label: "unilateral/Isolateral" },
                                            ]} />
                                        </Card>
                                    </Col>
                                    <div className="text-center mt-3">
                                        <Button color="primary" block onClick={handleAdvancedSearch}>Search</Button>
                                    </div>
                                </Row>
                            </Collapse>
                        </Col>
                        <Col lg={7} md={7} xs={10} >
                            <h1 className="text-center mb-4">Fittestwarrior Exercises</h1>
                            <div className='scrollable-col' >
                                {
                                    searchTerm === "" && filteredData.length > 0 ? <>
                                        <h3>Your Go-To Hub for Health and Fitness Tools</h3>
                                        <p className='font-format fs-4 '>
                                            Explore our curated library of exercises tailored for every skill level. With detailed instructions, images, and videos, users can confidently incorporate these exercises into their routines. From strength and flexibility to endurance and agility, there’s something for everyone looking to enhance their physical fitness.
                                            At FittestWarrior, we provide a wealth of resources to support our users in their health and fitness journeys. From exercise guides to nutrition tips, our platform is designed to offer practical, reliable tools that empower users to make positive lifestyle changes.
                                        </p>
                                    </>
                                        : null
                                }
                                {/* Exercise Cards */}
                                <Row>
                                    {
                                        filteredData.length ? filteredData.map((item, idx) => (
                                            <Col md="3" sm="6" className="mb-4" key={idx}>
                                                <Card className="__exercise-card shadow">
                                                    <div className="__image-wrapper position-relative">
                                                        <ExerciseLike />
                                                        <CardImg top src={image} alt="Exercise" className="__exercise-image" />
                                                    </div>
                                                    <CardBody className="text-center" style={{ height: '15  0px' }}>
                                                        <h6 className="__exercise-category">{item.exercise_name}</h6>
                                                        <p className="__exercise-date">{JSON.parse(item.area_of_body_part)}</p>
                                                        <Button color="dark" onClick={() => handleExerciseView(item.id)} className="__show-button">Show Details</Button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )) : (
                                            // <div className="text-center">No exercises found.</div>
                                            <>
                                                <Card>
                                                    <CardBody className='d-flex flex-column align-items-center justify-content-center'>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/nqtddedc.json"
                                                            trigger="loop"
                                                            colors="primary:#e83a30"
                                                            style={{ width: '250px', height: '250px' }}>
                                                        </lord-icon>
                                                        <h1>No exercises found</h1>
                                                    </CardBody>
                                                </Card>
                                            </>
                                        )
                                    }
                                </Row>
                                {
                                    searchTerm === "" && filteredData.length > 0 ? <>
                                        <p className='font-format fs-4 '>
                                            <b>Comprehensive Exercise Library for All Levels</b><br />
                                            Our exercise library offers a diverse range of workouts and training routines tailored for everyone—from beginners to advanced professionals. This comprehensive database includes detailed exercise data, images, and instructional videos, enabling users to explore and master techniques at their own pace.
                                        </p>
                                        <p className='font-format fs-4 '>
                                            <b>Visual and Easy-to-Follow Guides: </b>Each exercise includes clear images and videos, ensuring that users can easily understand and implement these exercises in their routines.
                                        </p>
                                        <p className='font-format fs-4 '>
                                            <b>Suitable for All Skill Levels: </b>We offer workouts for every level, whether you're just starting out or seeking advanced routines to push your limits. Our exercises cover a variety of fitness goals, from strength and conditioning to flexibility and endurance.
                                        </p>
                                        <p className='font-format fs-4 '>
                                            <b>Expert Insights, User-Driven Content: </b>FittestWarrior aggregates content from trusted experts and experienced users to create a valuable repository of fitness knowledge. Our platform offers these resources to users while acting as a third-party facilitator.
                                        </p>
                                        <p className='font-format fs-4 '>
                                            <b>Disclaimer: </b>Please note, as a third-party platform, FittestWarrior provides this content as-is based on the expertise and experience of our contributors. While we curate these exercises with care, we encourage users to consult healthcare or fitness professionals before starting new routines. We do not assume responsibility for the outcomes of individual exercises.
                                        </p>
                                    </>
                                        :
                                        null
                                }
                            </div>
                        </Col>

                        <Col md="2" lg={1} xs={2} className="media-position d-none d-lg-block">
                            <b>Follow</b>
                            <div className="justify-content-end">
                                <Link to="#"><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link><br />
                                <Link to="https://www.instagram.com/p/DCZFL_GTjNx/?igsh=MWU5Nnc3eWQzMGI3NA%3D%3D" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link><br />
                                <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link><br />
                            </div>

                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
};
